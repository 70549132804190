import { useMemo } from "react"

const LABEL_FILENAME = "File Name"
const LABEL_INVESTOR = "Investor"

const NA_NAME = 'NA'

const useColumnsConfig = (items = [], fileNames = []) => {
  const fileNameOptions = useMemo(() => {
    const mappedFileNames = items
      .filter(item => !!item.sourceFileName)
      .map(item => item.sourceFileName)

    const options = fileNames
      .map(fileName => ({
        value: fileName,
        label: fileName,
        disabled: mappedFileNames.includes(fileName)
      }))

    return options
  }, [ items, fileNames ])

  const investmentColumnProps = {
    title: LABEL_INVESTOR,
    ellipsis: true,
    editable: false,
    dataIndex: "name",
    render: (name, investment) => {
      const isNa = name === NA_NAME

      if (!isNa) {
        return name
      }

      const { investor } = investment

      if (!investor) {
        return name
      }

      const { familyName, givenName } = investor
      const investorName = `${givenName} ${familyName}`

      return `${investorName} - ${name}`
    }
  }

  const fileColumnProps = {
    title: LABEL_FILENAME,
    editable: false,
    dataIndex: "sourceFileName",
  }

  const hasFileOptions = fileNameOptions.length > 0

  if (hasFileOptions) {
    fileColumnProps.editable = true
    fileColumnProps.input = {
      type: "select",
      allowClear: true,
      options: () => fileNameOptions
    }
  }

  const columns = [
    { ...investmentColumnProps, width: "50%", },
    { ...fileColumnProps, width: "50%" },
  ]

  return columns
}

export default useColumnsConfig
