import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import Collection from "@components/Collection"
import { useAppContext } from "@components/AppContext"

import matchInvestments from "./helpers/matchInvestments"
import useColumnsConfig from "./helpers/useColumnsConfig"


const InvestmentFilesCollection = ({ collection, enableUpload }) => {
  const [ items, setItems ] = useState()
  const [ fileNames, setFileNames ] = useState([])
  const [ isMatching, setIsMatching ] = useState(false)

  const { request } = useAppContext()
  const { activeInvestments } = useOutletContext()

  const columnsConfig = useColumnsConfig(items, fileNames)

  const collectionItems = useMemo(() =>
    items || activeInvestments || []
  , [ items, activeInvestments ])

  useEffect(() => {
    const hasMappedFiles = collectionItems
      .filter(item => !!item.sourceFileName)
      .length > 0

    enableUpload(hasMappedFiles)
  }, [ collectionItems, enableUpload ])

  const matchItems = async (newFileNames) => {
    setIsMatching(true)

    const newItems = await matchInvestments(request, activeInvestments, newFileNames)
    setItems([ ...newItems ])

    setIsMatching(false)
  }

  collection.getItems = () => collectionItems

  collection.getUnmatchedFileNames = () => {
    const matchedFileNames = items
      .filter(({ sourceFileName }) => !!sourceFileName)
      .map(({ sourceFileName }) => sourceFileName)

    const unmatchedFilesName = fileNames
      .filter(name => !matchedFileNames.includes(name))

    return unmatchedFilesName
  }

  collection.setFileNames = async (newFileNames) => {
    setFileNames(newFileNames)
    await matchItems(newFileNames)
  }

  const onSave = updatedItem => {
    setItems(prevItems => prevItems.map(item => ({
      ...(item.id === updatedItem.id ? updatedItem : item)
    })))

    return Promise.resolve()
  }

  const enableSelectItem = false
  const selectedRowItems = collectionItems.map(item => item.id)

  const tableProps = {
    size: "small",
    rowKey: "id",
    loading: !activeInvestments || isMatching,
    bordered: false,
  }

  const collectionProps = {
    items: collectionItems,
    onSave,
    collection,
    tableProps,
    columnsConfig,
    enableSelectItem,
    selectedRowItems
  }

  return (
    <Collection {...collectionProps} />
  )
}

InvestmentFilesCollection.propTypes = {
  collection: PropTypes.shape().isRequired,
  enableUpload: PropTypes.func.isRequired,
}

export default InvestmentFilesCollection
